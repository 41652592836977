import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TextPopup = ({ show, onClose }) => {
  const { t } = useTranslation("global");

  if (!show) return null;

  return (
    <div className=" h-5/6 z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ">
      <div className="bg-footer text-xs z-20 p-8 rounded-lg text-white shadow-lg text-justify h-5/6 md:w-1/2 w-5/6 overflow-y-auto ">
        <h2 className="text-xl  font-bold mb-4">{t("politicas.title")}</h2>
        <p className="mb-2">{t("politicas.text1")}</p>
        <p className="mb-2">{t("politicas.text2")}</p>
        <p className="mb-2">{t("politicas.text3")}</p>
        <p className="mb-2">{t("politicas.text4")}</p>
        <p className="mb-2">{t("politicas.text5")}</p>
        <p className="mb-2">{t("politicas.text6")}</p>
        <p className="mb-2">{t("politicas.text7")}</p>
        <a className="mb-2" target="_blank" href="https://servicios.infoleg.gob.ar/infolegInternet/anexos/60000-64999/64790/norma.htm" style={{textDecoration:'underline'}}>https://servicios.infoleg.gob.ar/infolegInternet/anexos/60000-64999/64790/norma.htm</a>
        <p className="mb-2">{t("politicas.text8")}</p>
        <a className="mb-2" target="_blank" href="https://servicios.infoleg.gob.ar/infolegInternet/anexos/170000-174999/170508/norma.htm" style={{textDecoration:'underline'}}>https://servicios.infoleg.gob.ar/infolegInternet/anexos/170000-174999/170508/norma.htm</a>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="mt-6 text-base px-4 py-2 bg-gris-opaco text-white rounded-md"
          >
            {t("politicas.cerrar")}
          </button>
        </div>
      </div>
    </div>
  );
};

function Footer() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const currentYear = new Date().getFullYear();
  const [t, i18n] = useTranslation("global");

  const handleOpenPopup = (e) => {
    e.preventDefault(); // Evita el comportamiento predeterminado del evento
    setPopupVisible(true);
  };
  return (
    <>
      <div className="bg-footer w-screen h-16 flex items-center">
        <div className="container mx-auto flex flex-col md:flex-row justify-between text-xs md:text-sm">
          {/* Enlaces a la izquierda */}
          <div className="space-x-4 md:text-left text-center">
            <a 
              href="#" 
              className="hover:underline text-white"
              onClick={handleOpenPopup} // Abrir el popup cuando se haga clic
            >
              {t("footer.politicas")}
            </a>
          </div>

          {/* Derechos reservados */}
          <div className="text-center md:text-right text-white">
            © {currentYear} Resguarit | {t("footer.copyright")}
          </div>
        </div>
      </div>

      {/* Aquí es donde se muestra el Popup */}
      <TextPopup show={isPopupVisible} onClose={() => setPopupVisible(false)} />
    </>
  );
}

export default Footer;

