import { Menu } from 'lucide-react';
import Navbar from './Navbar'
import Traductor from './Traductor'
import React from 'react';
import MenuMovil from './MenuMovil';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
      element.scrollIntoView({ behavior: 'smooth', });
  }
};

function Header(){
    return(
        <>
            <div className="container ml-2 w-screen sm:mx-auto py-5 md:px-5 flex justify-between items-center fixed top-0 left-0 right-0 z-10">
                <div>
                <a onClick={() => scrollToSection('inicio')} className='cursor-pointer'>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        fill="white" stroke="none" className="size-12" viewBox="0 0 130.000000 130.000000"
                        preserveAspectRatio="xMidYMid meet">
                    
                    <g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)">
                    <path d="M504 1230 c-218 -57 -385 -221 -441 -433 -22 -84 -22 -235 1 -312 68
                    -233 251 -394 490 -433 89 -14 105 -14 189 1 167 29 306 118 395 251 60 90 89
                    171 104 294 9 72 9 95 -1 105 -9 9 -93 12 -327 12 -301 0 -316 -1 -330 -19
                    -19 -27 -18 -69 5 -97 18 -22 22 -23 270 -27 l252 -5 -6 -31 c-20 -102 -112
                    -222 -225 -295 -78 -51 -141 -65 -267 -59 -87 3 -108 8 -165 36 -79 39 -188
                    145 -223 217 -91 185 -56 395 89 539 73 72 164 120 257 135 35 6 132 11 215
                    11 158 0 218 6 208 21 -11 19 -129 71 -202 90 -94 24 -195 24 -288 -1z"/>
                    <path d="M690 994 c-243 -42 -344 -120 -381 -292 -21 -102 19 -231 94 -299
                    123 -110 340 -123 447 -26 22 19 40 39 40 43 0 4 -62 10 -137 13 -166 7 -207
                    18 -253 66 -65 68 -78 165 -32 251 48 92 54 94 414 101 169 3 310 8 314 12 13
                    13 -37 115 -59 121 -27 8 -412 17 -447 10z"/>
                    </g>
                    </svg>
                </a>
                </div>
                <div className='hidden md:flex justify-between items-center'>
                    <Navbar></Navbar>
                </div>
                <div className='hidden md:flex'>
                    <Traductor></Traductor>
                </div>
                <MenuMovil></MenuMovil>
                </div>

        </>
    )
}
export default Header;