import React from 'react';
import alltickets from '../assets/marcas/alltickets.png';
import allcash from '../assets/marcas/allcash.png';
import bailina from '../assets/marcas/bailina.png';
import creditok from '../assets/marcas/creditok.png';
import espai from '../assets/marcas/espai.png';
import fundacionedelp from '../assets/marcas/fundacion edelp.png';
import hielocity from '../assets/marcas/hielocity.png';
import idm from '../assets/marcas/idm.png';
import letrag from '../assets/marcas/letrag.png';
import maspesos from '../assets/marcas/maspesos.png';
import nogui from '../assets/marcas/nogui.png';
import prestimax from '../assets/marcas/prestimax.png';
import prontocash from '../assets/marcas/prontocash.png';
import santiagoyfrancisco from '../assets/marcas/santiagoyfrancisco.png';
import sef from '../assets/marcas/sef.png';
import solocash from '../assets/marcas/solocash.png';
import supercash from '../assets/marcas/supercash.png';
import supermercadolaamistad from '../assets/marcas/supermercado la amistad.png';
import toprod from '../assets/marcas/toprod.png';
import unnic from '../assets/marcas/unnic.png';
import { useTranslation } from 'react-i18next';

const Marcas = () => {
  const [t, i18n] = useTranslation("global");
  const brands1 = [alltickets, allcash, bailina, creditok, espai, fundacionedelp, hielocity, idm];
  const brands2 = [
    letrag, maspesos, nogui, prestimax, prontocash, santiagoyfrancisco,
sef, solocash, supercash, supermercadolaamistad, toprod, unnic
  ];

  // Duplicamos las marcas para el efecto continuo
  const duplicatedBrands1 = [...brands1, ...brands1, ...brands1];
  const duplicatedBrands2 = [...brands2, ...brands2, ...brands2];

  return (
    <div className='w-full h-[30rem] flex flex-col items-center justify-center'>
      <h1 className='text-xl md:text-4xl font-kanit font-bold tracking-wider text-white py-8'>
        {t("marcas.title")}
      </h1>
      <div className="w-[80%] font-kanit mx-auto text-white overflow-hidden relative h-64">
        {/* Primera fila */}
        <div className="absolute top-0 w-full">
          <div
            className="animate-scroll-left-mobile md:animate-scroll-left flex space-x-8"
            style={{ width: `${duplicatedBrands1.length * 5}rem` }} // Ajuste dinámico del ancho
          >
            {duplicatedBrands1.map((brand, index) => (
              <div
                key={`top-${index}`}
                className="flex-shrink-0 w-20 h-20 rounded-full flex items-center justify-center"
              >
                <img src={brand} alt={`brand-${index}`} className="w-full h-full object-contain" />
              </div>
            ))}
          </div>
        </div>
        
        {/* Segunda fila */}
        <div className="absolute top-32 w-full">
          <div
            className="animate-scroll-right-mobile md:animate-scroll-right flex space-x-8"
            style={{ width: `${duplicatedBrands2.length * 5}rem` }} // Ajuste dinámico del ancho
          >
            {duplicatedBrands2.map((brand, index) => (
              <div
                key={`bottom-${index}`}
                className="flex-shrink-0 w-20 h-20 rounded-full flex items-center justify-center"
              >
                <img src={brand} alt={`brand-${index}`} className="w-full h-full object-contain" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marcas;