import React from 'react';
import { useTranslation } from 'react-i18next';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
      element.scrollIntoView({ behavior: 'smooth', });
  }
};

const Navbar = () => {
  const [t, i18n] = useTranslation("global");
    return (
      <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center py-1 px-1 gap-x-5 bg-gris-opaco bg-opacity-45 text-white rounded-2xl">
        <button className="hover:bg-black transition duration-300 ease-in-out rounded-2xl px-3" onClick={() => scrollToSection('servicios')}>{t("navbar.servicios")}</button>
        <button className="hover:bg-black transition duration-300 ease-in-out rounded-2xl px-3" onClick={() => scrollToSection('productos')}>{t("navbar.productos")}</button>
        <button className="hover:bg-black transition duration-300 ease-in-out rounded-2xl px-3" onClick={() => scrollToSection('nosotros')}>{t("navbar.nosotros")}</button>
        <button className="bg-white text-black transition duration-300 ease-in-out rounded-2xl px-3" onClick={() => scrollToSection('contacto')}>{t("navbar.contacto")}</button>
      </div>


)
}
export default Navbar;



/*----------menu desplegable----------*/
{/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>
 */}