import React from 'react';

const Servicio = ({ imagen, titulo, descripcion }) => {
    return (
        <div className="mt-5 tracking-wider relative z-2 text-center w-full sm:w-full lg:w-[30%] group h-[300px] items-center overflow-hidden -mb-14 content-center">
            <img
                src={imagen}
                alt={titulo}
                className="mx-auto transition-transform duration-300 ease-in-out group-hover:-translate-y-96"
            />
            <div className="flex flex-col items-center -space-y-0 transition-transform duration-300 ease-in-out group-hover:-translate-y-36">
                <h1 className="text-xl text-gray-200 font-kanit p-6">{titulo}</h1>
                <span className="z-[-1] text-sm font-kanit bg-opacity-70 text-white opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100 p-4">
                    {descripcion}
                </span>
            </div>
        </div>
    );
};

export default Servicio;

