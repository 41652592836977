import Aplicacion from './components/Aplicacion';
import './styles/tailwind.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Tiquetera from './components/Tiquetera';
import Financiera from './components/Financiera';
import SistPersonalizado from './components/SistPersonalizado';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Aplicacion />} />
        <Route path="/productos/tiquetera" element={<Tiquetera></Tiquetera>} />
        <Route path="/productos/financiera" element={<Financiera></Financiera>} />
        <Route path="/productos/personalizados" element={<SistPersonalizado></SistPersonalizado>} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
