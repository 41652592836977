import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ProductoCarta from './ProductoCarta';
import ProductoCartaMovil from './ProductoCartaMovil';
import { useTranslation } from 'react-i18next';


const ProductoSeccion = () => {
  const [t, i18n] = useTranslation("global");
  const productos = [
    {
      title: t("productos.title-ticketera"),
      description: t("productos.descripcion-ticketera"),
      content: t("productos.contenido-ticketera"),
      linkText: t("productos.ver-mas"),
      linkNavigation: '/productos/tiquetera',
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="0.5" stroke="black" className="size-28">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
        </svg>
      ),
    },
    {
      title: t("productos.title-financiera"),
      description: t("productos.descripcion-financiera"),
      content: t("productos.contenido-financiera"),
      linkText: t("productos.ver-mas"),
      linkNavigation: '/productos/financiera',
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.5" stroke="black" class="size-28">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
        </svg>

      ),
    },
    {
      title: t("productos.title-personalizado"),
      description: t("productos.descripcion-personalizado"),
      content: t("productos.contenido-personalizado"),
      linkText: t("productos.ver-mas"),
      linkNavigation: '/productos/personalizados',
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.5" stroke="black" class="size-28">
          <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
        </svg>

      ),
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef(null);
  const touchStartX = useRef(null);

  // Check for mobile view
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Touch and swipe handling for mobile
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (!touchStartX.current) return;
    
    const touchEndX = e.changedTouches[0].clientX;
    const diffX = touchStartX.current - touchEndX;
    
    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
    
    touchStartX.current = null;
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex === productos.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? productos.length - 1 : prevIndex - 1));
  };

  const slideVariants = {
    initial: (direction) => ({
      x: direction > 0 ? '150%' : '-150%',
      scale: 0.8,
      opacity: 0
    }),
    animate: {
      x: 0,
      scale: 1,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.4
      }
    },
    exit: (direction) => ({
      x: direction > 0 ? '-150%' : '150%',
      scale: 0.8,
      opacity: 0,
      transition: {
        type: 'tween',
        duration: 0.4
      }
    })
  };

  return (
    <div 
      id="productos" 
      className="min-h-screen flex flex-col justify-center px-4 py-16 font-kanit"
    >
      <div className="w-full max-w-6xl mb-10">
        <h2 className="text-xl md:text-4xl text-white font-kanit font-bold text-center tracking-wider lg:text-left">
        {t("productos.title")}
        </h2>
      </div>

      {/* Mobile Slider */}
      <div 
        className="w-full max-w-6xl flex items-center justify-center sm:space-x-8 lg:hidden relative"
        ref={carouselRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <button 
          onClick={handlePrev} 
          className="text-white hidden sm:flex p-2 rounded-full bg-black/50 hover:bg-black transition z-1"
        >
          <ChevronLeft size={32} />
        </button>

        <div className='flex flex-col items-center justify-center w-full gap-5'>
          <div className="relative w-[18rem] h-[25rem] overflow-hidden">
            <AnimatePresence custom={direction} mode="wait">
              <motion.div
                key={currentIndex}
                custom={direction}
                variants={slideVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                className="absolute w-full h-full"
              >
                <ProductoCartaMovil
                  title={productos[currentIndex].title}
                  description={productos[currentIndex].description}
                  linkText={productos[currentIndex].linkText}
                  linkNavigation={productos[currentIndex].linkNavigation}
                  svg={productos[currentIndex].svg}
                />
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            {[...productos].map((_, index) => (
              <div 
                key={index} 
                className={`w-3 h-3 rounded-full border-2 border-white 
                  ${index === currentIndex ? 'bg-white' : 'bg-transparent'}`}
              />
            ))}
          </div>
        </div>

        <button 
          onClick={handleNext} 
          className="text-white p-2 hidden sm:flex rounded-full bg-black/50 hover:bg-black transition z-1"
        >
          <ChevronRight size={32} />
        </button>
      </div>

      {/* Desktop Grid */}
      <div className="hidden lg:grid grid-cols-3 gap-10 w-full mx-auto">
        {productos.map((producto, index) => (
          <ProductoCarta
            key={index}
            title={producto.title}
            description={producto.description}
            content={producto.content}
            linkText={producto.linkText}
            linkNavigation={producto.linkNavigation}
            svg={producto.svg}
          />
        ))}
      </div>
      <div className="w-full max-w-6xl mb-10"></div>
    </div>
  );
};

export default ProductoSeccion;
