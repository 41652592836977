import React from 'react';
import Servicio from './Servicio';
import seguridad from '../assets/images/seguridad.png';
import desarrolloweb from '../assets/images/desarrollo-web.png';
import marketing from '../assets/images/marketing.png';
import soporte from '../assets/images/soporte.png';
import derecho from '../assets/images/derecho.png';
import { useTranslation } from 'react-i18next';

const Servicios = () => {
    const [t, i18n] = useTranslation("global");
    const servicios = [
        {
            imagen: seguridad,
            titulo: t("servicios.title-seguridad"),
            descripcion: t("servicios.texto-seguridad"),
        },
        {
            imagen: desarrolloweb,
            titulo: t("servicios.title-desarrollo"),
            descripcion: t("servicios.texto-desarrollo"),
        },
        {
            imagen: derecho,
            titulo: t("servicios.title-derecho"),
            descripcion: t("servicios.texto-derecho"),
        },
        {
            imagen: marketing,
            titulo: t("servicios.title-marketing"),
            descripcion: t("servicios.texto-marketing"),
        },
        {
            imagen: soporte,
            titulo: t("servicios.title-soporte"),
            descripcion: t("servicios.texto-soporte"),
        },
    ];

    return (
        <div id="servicios" className="mx-auto flex justify-center items-center">
            <div className="w-full justify-center justify-items-center mx-auto px-4">
                <h1 className="text-xl w-full font-kanit font-bold text-white mt-10 text-center md:text-4xl lg:text-start">{t("servicios.title")}</h1>  
                <div className="bg-fondo-opaco overflow-hidden bg-opacity-50 rounded-xl flex flex-wrap justify-center items-start gap-4 mt-10 p-6 min-h-[80vh] content-center">
                    {servicios.map((servicio, i) => (
                        <React.Fragment key={i}>
                            <Servicio
                                imagen={servicio.imagen}
                                titulo={servicio.titulo}
                                descripcion={servicio.descripcion}
                            />
                            {i < servicios.length - 1 && (
                                <div className="w-full border-b lg:hidden my-4 border-gray-600"></div>
                            )}
                            {i < servicios.length - 1 && (i + 1) % 3 !== 0 && (
                                <div className="hidden lg:block border-l border-gray-600 h-32 self-start mt-8"></div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};  

export default Servicios;

