import React from 'react';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'react-i18next';
const Hero = () => {
    const [t, i18n] = useTranslation("global");
    return (
        <div className="min-h-screen font-kanit w-full flex flex-col items-center justify-center text-white px-4 text-center">
            <h1 className="font-bold mb-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                Resguar IT
            </h1>
            <div className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mx-auto">
                <Typewriter 
                    options={{
                        strings: [t("hero.slogan1"), t("hero.slogan2")],
                        autoStart: true,
                        loop: true,
                        delay: 50,
                    }}
                />
                
            </div>
        </div>
    );
};

export default Hero;
