import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function TiqueteraLanding() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const handleGoBack = () => {
    navigate('/'); 
    setTimeout(() => {
      const contactElement = document.getElementById('productos');
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);  };

  const handleContact = () => {
    navigate('/'); // Navega a la ruta principal
    setTimeout(() => {
      const contactElement = document.getElementById('contacto');
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500); 
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-black to-zinc-900">
      {/* Back Button */}
      <div className="fixed top-4 left-4 z-50">
        <button 
          className="flex items-center px-4 py-2 text-white hover:bg-white/10 rounded-lg transition-colors"
          onClick={handleGoBack}
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-4 w-4 mr-2" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          {t("tiquetera.volver")}
        </button>
      </div>

      <main className="container mx-auto px-4 pt-16">
        {/* Hero Section */}
        <section className="pt-16 pb-16 text-center">
          <h1 className="text-5xl md:text-7xl font-bold text-white mb-4">
          {t("tiquetera.title")}
          </h1>
          <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
          {t("tiquetera.texto-2")}
          </p>
          <button 
            className="px-6 py-3 text-lg bg-white text-black hover:bg-gray-200 rounded-lg transition-colors"
            onClick={handleContact}
          >
            {t("tiquetera.texto-3")}
          </button>
        </section>

        {/* Features Section */}
        <section className="py-16">
          <div className="bg-zinc-800/70 backdrop-blur rounded-lg p-8 shadow-lg">
            <h2 className="text-4xl font-bold text-white mb-8 text-center">
            {t("tiquetera.texto-9")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  title: t("tiquetera.title-4"),
                  description: t("tiquetera.descripcion-4")
                },
                {
                  title: t("tiquetera.title-5"),
                  description: t("tiquetera.descripcion-5")
                },
                {
                  title: t("tiquetera.title-6"),
                  description: t("tiquetera.descripcion-6")
                },
                {
                  title: t("tiquetera.title-7"),
                  description: t("tiquetera.descripcion-7")
                },
                {
                  title: t("tiquetera.title-8"),
                  description: t("tiquetera.descripcion-8")
                },
                {
                  title: t("tiquetera.title-9"),
                  description: t("tiquetera.descripcion-9")
                }
              ].map((feature, index) => (
                <div key={index} className="bg-zinc-900/50 p-6 rounded-lg space-y-3 hover:bg-zinc-700/50 transition-colors">
                  <h3 className="text-2xl font-semibold text-white">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="py-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col justify-center items-center bg-zinc-900/50 backdrop-blur rounded-lg p-8">
            <div className="text-5xl font-bold text-white mb-2">5000+</div>
            <div className="text-gray-400">{t("tiquetera.texto-4")}</div>
          </div>
          <div className="flex flex-col justify-center items-center  bg-zinc-900/50 backdrop-blur rounded-lg p-8">
            <div className="text-5xl font-bold text-white mb-2">12+</div>
            <div className="text-gray-400">{t("tiquetera.texto-5")}</div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 text-center">
          <div className="bg-zinc-900/50 backdrop-blur rounded-lg p-8">
            <h2 className="text-3xl font-bold text-white mb-4">
            {t("tiquetera.texto-6")}
            </h2>
            <p className="text-xl text-gray-300 mb-8">
            {t("tiquetera.texto-7")}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button 
                className="px-6 py-3 text-lg bg-white text-black hover:bg-gray-200 rounded-lg transition-colors"
                onClick={handleContact}
              >
                {t("tiquetera.texto-8")}
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}