import React, { useState, useRef, useEffect } from 'react';
import Mapa from './Mapa';
import RedesResponsive from './RedesResponsive';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

function ContactoForm() {
    const [t, i18n] = useTranslation("global");
    const [formData, setFormData] = useState({
        nombre: "",
        email: "",
        telefono: "",
        motivo: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef(); // Referencia al reCAPTCHA
    const formRef = useRef(); // Referencia al formulario
    const maxWords = 100;

    const Alert = ({ type, message, onClose }) => {
        useEffect(() => {
          const timer = setTimeout(() => {
            onClose();
          }, 5000); // Automatically close after 5 seconds
      
          return () => clearTimeout(timer);
        }, [onClose]);
      
        const typeStyles = {
          success: 'bg-green-500',
          error: 'bg-red-500'
        };
      
        return (
          <div className={`fixed top-4 right-4 z-50 p-4 text-white rounded ${typeStyles[type]}`}>
            {message}
            <button 
              onClick={onClose} 
              className="ml-4 text-white hover:opacity-75"
            >
              ✕
            </button>
          </div>
        );
      };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validar límite de palabras en "motivo"
        if (name === "motivo") {
            const words = value.trim().split(/\s+/);
            if (words.length > maxWords) {
                setFormData({ ...formData, motivo: words.slice(0, maxWords).join(" ") });
                setError(true);
                return;
            } else {
                setError(false);
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    const [alert, setAlert] = useState({
        show: false,
        type: '', // 'success' or 'error'
        message: ''
    });

    const sendEmail = async () => {
        setLoading(true);
        try {
            // Ejecutar reCAPTCHA y obtener el token
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            if (token) {
                // Enviar datos con EmailJS
                await emailjs.sendForm(
                    "service_uyu2unq", // Reemplaza con tu Service ID
                    "template_281gm9h", // Reemplaza con tu Template ID
                    formRef.current, // Referencia al formulario
                    "UxPKyHtfM6Ed0d_oy" // Reemplaza con tu Public Key
                );
                
                setFormData({
                  nombre: "",
                  email: "",
                  telefono: "",
                  motivo: "",
              });
                setLoading(false);
                setAlert({
                    show: true,
                    type: 'success',
                    message: t("contacto.envio-exitoso") // Add this translation
                  });
            } else {
                setLoading(false);
                setAlert({
                    show: true,
                    type: 'error',
                    message: t("contacto.error-recaptcha") // Add this translation
                  });
            }
            setShowModal(false);
        } catch (error) {
            console.error("Error al enviar el formulario:", error);
            setLoading(false);
            setAlert({
                show: true,
                type: 'error',
                message: t("contacto.error-envio") // Add this translation
              });
            setShowModal(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModal(true); // Mostrar modal de confirmación
    };

    const confirmSubmit = async () => {
        await sendEmail(); // Enviar formulario
    };

    const Modal = ({ onConfirm, onCancel }) => (
        <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-footer z-20 p-8 rounded-lg text-white shadow-lg text-center w-11/12 md:w-1/3">
                <h2 className="text-xl font-bold mb-4">{t("contacto.confirmar-titulo")}</h2>
                <p className="mb-6">{t("contacto.confirmar-pregunta")}</p>
                <div className="flex justify-around">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-gris-opaco text-white rounded-md"
                    >
                        {t("contacto.cancelar")}
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-green-500 text-white rounded-md"
                    >{loading ? t("contacto.enviando") : t("contacto.confirmar")}</button>
                </div>
            </div>
        </div>
    );

    return (
        <div id="contacto" className="min-h-screen items-center w-full flex flex-col justify-center mx-auto pt-16 pb-5">
            <div className="w-full justify-start">
                <h2 className="text-white w-full lg:mx-10 inline-block font-kanit font-bold tracking-wider text-xl md:text-4xl lg:text-start text-center">
                {t("contacto.title")}
                </h2>
            </div>
            <div className="w-full lg:mt-8 mt-5 flex flex-col md:flex-row gap-8 lg:gap-20 justify-center items-center mx-auto">
                {/* Formulario de contacto */}
                <div className="rounded-xl p-[2px] h-[420px] md:h-[400px] bg-gradient-to-b from-[#FFFFFF] to-[#858585] w-full md:w-1/2 lg:w-2/5 items-center">
                    <div className="rounded-xl w-full bg-footer flex flex-col justify-between h-full">
                        <form
                            ref={formRef}
                            className="text-white flex flex-col w-full h-full font-kanit justify-between"
                            onSubmit={handleSubmit}
                        >
                            <label className="mt-4 ml-8 md:ml-12 md:text-xl text-lg font-thin">{t("contacto.nombre")}</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                className="px-4 rounded-xl bg-gris-grad border h-7 w-5/6 mx-auto"
                                required
                            />
                            <label className="md:mt-3 mt-2 ml-8 md:ml-12 md:text-xl text-lg font-thin">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="px-4 rounded-xl bg-gris-grad border h-7 w-5/6 mx-auto"
                                required
                            />
                            <label className="md:mt-3 mt-2 ml-8 md:ml-12 md:text-xl text-lg font-thin">{t("contacto.telefono")}</label>
                            <input
                                type="text"
                                name="telefono"
                                value={formData.telefono}
                                onChange={handleChange}
                                className="px-4 rounded-xl bg-gris-grad border h-7 w-5/6 mx-auto"
                            />
                            <label className="md:mt-3 mt-2 ml-8 md:ml-12 md:text-xl text-lg font-thin">{t("contacto.motivo")}</label>
                            <textarea
                                name="motivo"
                                value={formData.motivo}
                                onChange={handleChange}
                                className="px-4 rounded-xl bg-gris-grad border w-5/6 mx-auto h-20 font-thin"
                                placeholder={`${t("contacto.palabras")} ${maxWords}`}

                            ></textarea>
                            {error && <p className="md:ml-12 ml-8 text-red-500 text-sm">{t("contacto.error")}</p>}
                            <button
                                type="submit"
                                className="mb-3 w-5/6 bg-botonEnviar mt-6 md:text-xl text-lg h-10 border rounded-xl mx-auto font-thin"
                            >
                                {t("contacto.enviar")}
                            </button>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Lc3wosqAAAAAO66SUuwqZG47532dN2yhZ6zg4u5"
                                size="invisible"
                                theme="dark"
                                className="hidden"
                            />
                        </form>
                        {showModal && <Modal onConfirm={confirmSubmit} onCancel={() => setShowModal(false)} />}
                    </div>
                </div>

                {alert.show && (
                    <Alert 
                        type={alert.type} 
                        message={alert.message} 
                        onClose={() => setAlert({ ...alert, show: false })} 
                    />
                )}

                {/* Mapa */}
                <div className="flex flex-col md:w-1/2 lg:w-2/5 z-0">
                    <h2 className="text-white h-10 inline-block font-kanit text-base text-start">
                        {t("contacto.encontrar")}
                    </h2>
                    <p className="text-xs font-kanit text-start">Calle 47 Nº 537 entre 5 y 6, La Plata, Buenos Aires</p>
                    <div className="rounded-lg shadow-lg w-full mt-2 md:h-[330px] h-[320px]">
                        <Mapa />
                    </div>
                </div>
            </div>
            <RedesResponsive />
        </div>
    );
}

export default ContactoForm;