import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductoCartaMovil = ({ title, description, linkText, linkNavigation, svg }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (linkNavigation) {
      navigate(linkNavigation); // Navega dinámicamente al enlace proporcionado
    }
  };

  return (
    <div className="relative h-[25rem] w-full sm:w-[18rem] group mx-auto">
        {/* Front Face */}
        <div className="absolute h-full w-full rounded-lg bg-gradient-to-b from-[#D0CFCF] to-[#797979] shadow-inner flex flex-col">
          <div className="flex flex-col items-center gap-5 h-full py-5 px-10">
            <h3 className="text-xl md:text-2xl font-bold text-black text-center">{title}</h3>
            <h4 className='text-lg md:text-xl text-black text-center'>{description}</h4>
          </div>
          <div className='flex flex-col items-center h-full'>
            {svg}
          </div>
          <div className="flex justify-center items-center text-black py-5">
              <a 
                onClick={handleLinkClick} 
                className="text-xl text-right relative inline-block"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {linkText}
                <span 
                  className={`absolute bottom-0 left-0 h-0.5 bg-black transition-all duration-300 ease-in-out ${
                    isHovered ? 'w-full' : 'w-0'
                  }`}
                ></span>
              </a>
            </div>
        </div>
        
      </div>
  );
};

export default ProductoCartaMovil;