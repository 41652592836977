import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductoCarta = ({ title, content, description, linkText, linkNavigation, svg }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (linkNavigation) {
      navigate(linkNavigation); // Navega dinámicamente al enlace proporcionado
    }
  };

  return (
    <div className="relative h-[25rem] w-full sm:w-[18rem] [perspective:1000px] group mx-auto">
      <div className="absolute h-full w-full transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        {/* Front Face */}
        <div className="absolute inset-0 h-full w-full rounded-lg bg-gradient-to-b from-[#D0CFCF] to-[#797979] shadow-inner [backface-visibility:hidden] flex flex-col">
          <div className="flex flex-col items-center gap-5 h-full py-5 px-10">
            <h3 className="text-xl md:text-2xl font-bold text-black text-center">{title}</h3>
            <h4 className="text-lg md:text-xl text-black text-center">{description}</h4>
          </div>
          <div className='flex flex-col items-center h-full'>
            {svg}
          </div>
        </div>

        {/* Back Face */}
        <div className="absolute inset-0 h-full w-full rounded-lg bg-gradient-to-b from-[#D0CFCF] to-[#797979] shadow-inner px-10 text-center text-white [transform:rotateY(180deg)] [backface-visibility:hidden] flex flex-col">
          <div className="flex min-h-full flex-col text-black items-center justify-around py-5">
            <p className="text-lg text-left">{content}</p>
            <div className="relative">
              <a
                onClick={handleLinkClick}
                className="text-xl text-right relative cursor-pointer inline-block"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {linkText}
                <span
                  className={`absolute bottom-0 left-0 h-0.5 bg-black transition-all duration-300 ease-in-out ${
                    isHovered ? 'w-full' : 'w-0'
                  }`}
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductoCarta;
