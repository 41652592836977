import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useTranslation } from 'react-i18next';

const Nosotros = () => {
    const [counterOn, setCounterOn] = useState(false);
    const [t, i18n] = useTranslation("global");
    return (
        
        <div id='nosotros' className="text-white mx-10 min-h-screen flex flex-col justify-center text-center items-center">
                <div>
            <div className="py-4 text-xl text-center md:text-4xl font-kanit lg:text-start font-bold tracking-wider">
                <h2>{t("nosotros.title")}</h2>
            </div>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className="bg-fondo-opaco bg-opacity-50 text-base md:text-xl rounded-xl py-4 px-8 font-kanit leading-normal">
                <p className="md:text-justify text-start">{t("nosotros.main-text")}</p>
                <div className="flex gap-6 py-4 tracking-wide">
                    <div className="">
                        <h4 className="md:text-4xl text-xl font-semibold">{counterOn && <CountUp start={0} end={400} duration={3} delay={0.2}/>}+</h4>
                        <p>{t("nosotros.proyectos")}</p>
                    </div>
                    <div className="">
                        <h4 className="md:text-4xl text-xl font-semibold">{counterOn && <CountUp start={0} end={20} duration={4} delay={0.2}/>}+</h4>
                        <p>{t("nosotros.experiencia")}</p>
                    </div>
                </div>
            </div>
            </ScrollTrigger>
            </div>
        </div>
        
    )
}
export default Nosotros;