import React from 'react';
import Header from './Header';
import RedesSociales from './RedesSociales';
import Nosotros from './Nosotros';
import ContactoForm from './ContactoForm';
import Footer from './Footer';
import ProductoSeccion from './ProductoSeccion';
import ServiciosSeccion from './ServiciosSeccion';
import Hero from './Hero';
import Marcas from './Marcas';
import RedesResponsive from './RedesResponsive';

function Aplicacion() {
    return (
        <>
            <div className="min-h-screen w-full flex flex-col items-center justify-center overflow-hidden bg-cover bg-z-[-1] bg-[url('../assets/images/fondoImagenNegro.png')]">
                <header className="w-full z-10">
                    <Header />
                </header>
                <div className="w-full flex flex-col items-center justify-center z-20">
                    <RedesSociales />
                </div>
                <main id="inicio" className="flex flex-col items-center justify-center text-white w-full">
                    <div className="w-full px-4 sm:px-6 lg:px-8">
                        <Hero />
                        <ServiciosSeccion />
                        <ProductoSeccion />
                        <Marcas />
                        <Nosotros />
                        <ContactoForm />
                    </div>
                </main>

                <footer className="z-10">
                    <Footer />
                </footer>
            </div>
      </>
    );
}

export default Aplicacion;
