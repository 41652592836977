import React, { useState } from 'react';
import Traductor from './Traductor';
import { useTranslation } from 'react-i18next';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const MenuMovil = () => {
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (sectionId) => {
    scrollToSection(sectionId);
    setIsOpen(false);
  };

  return (
    <>
      {/* Hamburger Button */}
      <button 
        onClick={toggleMenu} 
        className="md:hidden z-30 absolute right-4 top-6"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          strokeWidth={1.5} 
          stroke="white" 
          className="size-8"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" 
          />
        </svg>
      </button>

      {/* Mobile Menu Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-30 flex flex-col"
        >
          {/* Close Button and Language Selector */}
          <div className="flex justify-between p-6">
            <button 
              onClick={toggleMenu} 
              className="text-white"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24" 
                strokeWidth={1.5} 
                stroke="white" 
                className="size-8"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M6 18 18 6M6 6l12 12" 
                />
              </svg>
            </button>
            
            <Traductor />
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col items-start px-10 py-10 justify-start space-y-6 flex-grow text-white">
            <div className='flex w-full items-center justify-between' onClick={() => handleNavigation('servicios')} >
              <button className="text-3xl">
              {t("navbar.servicios")}
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
            <div className='flex w-full items-center justify-between' onClick={() => handleNavigation('productos')} >
              <button className="text-3xl">
              {t("navbar.productos")}
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
            <div className='flex w-full items-center justify-between' onClick={() => handleNavigation('nosotros')} >
              <button className="text-3xl">
              {t("navbar.nosotros")}
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
            <div className='flex w-full items-center justify-between' onClick={() => handleNavigation('contacto')} >
              <button className="text-3xl">
              {t("navbar.contacto")}
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuMovil;